<script setup lang="ts">
import { useEducationStore } from '~/src/features/education/infrastructure/store';

const store = useEducationStore();
const { selectedText } = storeToRefs(store);
</script>

<template>
	<div
		class="flex text-gray-400 text-sm font-normal"
	>
		{{ selectedText }}
	</div>
</template>

<style scoped>

</style>
